import type { App } from 'vue'
import type { PrimeVueConfiguration } from 'primevue/config'

import 'primeicons/primeicons.css'
import PrimeVue from 'primevue/config'
import DialogService from 'primevue/dialogservice'
import ToastService from 'primevue/toastservice'
import FocusTrap from 'primevue/focustrap'
import StyleClass from 'primevue/styleclass'
import Ripple from 'primevue/ripple'
import Tooltip from 'primevue/tooltip';

import { definePreset } from '@primevue/themes'
import Aura from '@primevue/themes/aura'

const CustomPreset = definePreset(Aura, {
  // see https://primevue.org/theming/styled/#customization
  // ...
})

export function install(app: App) {
  app.use<[PrimeVueConfiguration]>(PrimeVue, {
    theme: {
      preset: CustomPreset,
      options: {
        cssLayer: {
          name: 'primevue',
        },
        darkModeSelector: 'html.dark',
      },
    },
    ripple: true,
  })

  app.use(DialogService)
  app.use(ToastService)
  app.directive('focustrap', FocusTrap)
  app.directive('styleclass', StyleClass)
  app.directive('ripple', Ripple)
  app.directive('tooltip', Tooltip)
}
